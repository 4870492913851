<template>
  <section class="Login">
    <header class="Login-header">
      <img
        src="@/assets/images/logo.png"
        alt="VehPark - Vehicles I need"
        class="logo"
      />
      <img
        src="@/assets/images/logo-mobidata.png"
        alt="Logo Mobivia Database"
        class="logo"
      >
    </header>

    <main class="Login-main container">
      <b-field label="Entreprise" label-for="buisnessBrand">
        <b-select
          v-model="buisnessBrand"
          placeholder="Sélectionner une entreprise"
          id="buisnessBrand"
          expanded
        >
          <option
            v-for="brand in buisnessBrandGroup"
            :key="brand"
          >
            {{ brand }}
          </option>
        </b-select>
      </b-field>

      <b-button
        v-if="submitIsVisible"
        @click="goToHome"
      >Valider</b-button>
    </main>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "HomeSelection",
  data() {
    return {
      buisnessBrand: '',
      groupBy: 'enseigne'
    }
  },
  mounted() {
    this.getBusinessBrands()
  },
  computed: {
    ...mapGetters('user', ['businessBrands']),
    buisnessBrandGroup() {
      return this.businessBrands.length > 0 ? this.businessBrands.reduce((result, brand) => {
        if (!Array.isArray(result)) {
          result = []
        }
        if (!result.includes(brand[this.groupBy])) {
          result.push(brand[this.groupBy])
        }
        return result
      }) : []
    },
    submitIsVisible() {
      return this.buisnessBrand != ''
    }
  },
  methods: {
    ...mapActions('user', ['getBusinessBrands']),
    goToHome() {
      sessionStorage.setItem('vp_buisness_brand', this.buisnessBrand)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
